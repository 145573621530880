import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

const LoginScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { theme } = useTheme();
  const styles = getStyles(theme);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Send innloggingsforespørsel til backend
    const response = await fetch('https://pmh-intern.no:8502/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (data.token) {
      // Lagre token i localStorage
      localStorage.setItem('token', data.token);
      // Naviger til hjemmesiden
      navigate('/');
    } else {
      setErrorMessage(data.message || 'Innlogging mislyktes, prøv igjen.');
    }
  };

  return (
    <div style={styles.loginContainer}>
      <div style={styles.loginCard}>
        <h2 style={styles.headerText}>Logg inn</h2>
        <p style={styles.subHeaderText}>Velkommen til PMH's internside</p>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Brukernavn"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.loginInput}
          />
          <input
            type="password"
            placeholder="Passord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.loginInput}
          />
          <button type="submit" style={styles.loginButton}>Logg inn</button>
        </form>
        {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
        <p style={styles.registerText}>
          Har du ikke en konto? <Link to="/register" style={styles.registerLink}>Registrer deg</Link>
        </p>
      </div>
    </div>
  );
};

const getStyles = (theme) => {
  const isLight = theme === 'light';

  return {
    loginContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      background: isLight ? '#f0f2f5' : '#1a1a1a',
      transition: 'background 0.3s ease',
    },
    loginCard: {
      backgroundColor: isLight ? '#ffffff' : '#2c2c2c',
      padding: '40px',
      borderRadius: '15px',
      boxShadow: isLight ? '0 10px 30px rgba(0, 0, 0, 0.1)' : '0 10px 30px rgba(0, 0, 0, 0.5)',
      textAlign: 'center',
      maxWidth: '400px',
      width: '100%',
      boxSizing: 'border-box',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    },
    headerText: {
      color: isLight ? '#333' : '#d3d754',
      marginBottom: '10px',
      fontSize: '2rem',
      fontWeight: 'bold',
      transition: 'color 0.3s ease',
    },
    subHeaderText: {
      color: isLight ? '#666' : '#aaa',
      marginBottom: '30px',
      fontSize: '1rem',
      transition: 'color 0.3s ease',
    },
    loginInput: {
      width: 'calc(100% - 20px)',
      padding: '15px',
      marginBottom: '20px',
      backgroundColor: isLight ? '#f9f9f9' : '#3d3d3d',
      border: `2px solid ${isLight ? '#ddd' : '#555'}`,
      borderRadius: '8px',
      fontSize: '1rem',
      color: isLight ? '#333' : '#f9f9f9',
      transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
      boxSizing: 'border-box',
    },
    loginButton: {
      width: 'calc(100% - 20px)',
      padding: '15px',
      backgroundColor: isLight ? '#15606e' : '#4c9e9e',
      color: '#ffffff',
      border: 'none',
      borderRadius: '8px',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
      boxSizing: 'border-box',
    },
    registerText: {
      marginTop: '20px',
      color: isLight ? '#666' : '#aaa',
      fontSize: '1rem',
      transition: 'color 0.3s ease',
    },
    registerLink: {
      color: isLight ? '#15606e' : '#4c9e9e',
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'color 0.3s ease',
    },
    errorMessage: {
      color: 'red',
      marginTop: '10px',
      fontSize: '1rem',
      transition: 'color 0.3s ease',
    },
  };
};

export default LoginScreen;