import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-rotatedmarker'; // Import rotated marker support
import { apiSFTPAppFetch } from '../functions/apiSFTPAppService';

const MapScreen = () => {
  const [shipPositions, setShipPositions] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const { theme } = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    const fetchShipData = async () => {
      try {
        const data = await apiSFTPAppFetch('/ship_info_and_last_position');
        const validShips = Object.values(data).filter(
          (ship) => ship.latitude && ship.longitude
        );

        setShipPositions(validShips);
        setLoading(false);
      } catch (error) {
        console.error('Feil ved henting av skipets posisjoner:', error);
        setLoading(false);
      }
    };

    fetchShipData();
  }, []);

  const getColorForHull = (hull, isActive) => {
    if (isActive) return "#ff0000";
    if (hull === 0) return "#f6c23e";
    if (hull === 1) return "#4e73df";
    if (hull === 2) return "#1cc88a";
    if (hull === 3) return "#36b9cc";
    return "#6c757d";
  };

  const handleSearch = (text) => {
    setSearchQuery(text);

    if (text.length === 0) {
      setSearchResults([]);
      return;
    }

    const results = shipPositions.filter((ship) => {
      const searchText = text.toLowerCase();
      return (
        (ship.mmsi && ship.mmsi.toString().toLowerCase().includes(searchText)) ||
        (ship.call_sign && ship.call_sign.toLowerCase().includes(searchText)) ||
        (ship.name && ship.name.toLowerCase().includes(searchText)) ||
        (ship.prev_names && Array.isArray(ship.prev_names) && ship.prev_names.some((prevName) => prevName.toLowerCase().includes(searchText)))
      );
    });

    setSearchResults(results);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleMarkerClick = (ship) => {
    setActiveMarker(ship);
  };

  const MapMarkers = () => {
    const map = useMap();
    const markerLayerRef = useRef(null);

    useEffect(() => {
      if (markerLayerRef.current) {
        map.removeLayer(markerLayerRef.current);
      }

      const markerLayer = L.layerGroup();

      // Generer markører og legg dem til markerLayer
      shipPositions.forEach((ship) => {
        const heading = ship.true_heading !== null ? ship.true_heading : ship.course_over_ground;
        const rotation = heading ? parseFloat(heading) : 0;
        const color = getColorForHull(ship.hull, activeMarker && activeMarker.mmsi === ship.mmsi);

        const icon = new L.DivIcon({
          className: 'custom-icon',
          html: `<svg height="30" width="30" viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
                   <polygon points="400,0 600,600 400,500 200,600" fill="${color}" stroke="black" stroke-width="10" />
                 </svg>`,
          iconAnchor: [15, 15]
        });

        const marker = L.marker([ship.latitude, ship.longitude], {
          icon: icon,
          rotationAngle: rotation,
        });

        marker.on('click', () => handleMarkerClick(ship));
        markerLayer.addLayer(marker);
      });

      markerLayer.addTo(map);
      markerLayerRef.current = markerLayer;
    }, [shipPositions, activeMarker, map]);

    return null;
  };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <input
          style={styles.searchInput}
          placeholder="Søk..."
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {searchQuery.length > 0 && (
          <button style={styles.clearButton} onClick={clearSearch}>X</button>
        )}
        <button style={styles.settingsButton} onClick={() => setModalVisible(!modalVisible)}>☰</button>
      </div>

      {searchResults.length > 0 && (
        <div style={styles.searchResultsList}>
          {searchResults.map((item) => (
            <div
              key={item.mmsi}
              style={styles.searchResult}
              onClick={() => {
                setSearchQuery(item.name);
                setSearchResults([]);
                clearSearch();
                setActiveMarker(item);
              }}
            >
              <span style={styles.searchResultName}>{item.name}</span>
              <span style={styles.searchResultMatch}>MMSI: {item.mmsi}</span>
            </div>
          ))}
        </div>
      )}

      <MapContainer center={[65.5, 13]} zoom={5} style={styles.map}>
        <TileLayer
          url={theme === 'light' ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" : "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"}
          attribution={
            theme === 'light'
              ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              : '&copy; <a href="https://carto.com/attributions">CARTO</a>'
          }
        />
        <MapMarkers />
      </MapContainer>

      {activeMarker && (
        <div style={styles.infoPanel}>
          <button style={styles.closePanelButton} onClick={() => setActiveMarker(null)}>X</button>
          <h2>{activeMarker.name}</h2>
          <p><strong>IMO:</strong> {activeMarker.imo || '-'}</p>
          <p><strong>MMSI:</strong> {activeMarker.mmsi}</p>
          <p><strong>Kallesignal:</strong> {activeMarker.call_sign}</p>
          <p><strong>Tidligere båtnavn:</strong> {Array.isArray(activeMarker.prev_names) ? activeMarker.prev_names.join(', ') : '-'}</p>
          <p><strong>Verft:</strong> {activeMarker.shipyard || 'Grovgjord Mek. Verksted AS'}</p>
          <p><strong>Bygg nr.:</strong> {activeMarker.build_number || '103'}</p>
          <p><strong>Rederi:</strong> {activeMarker.owner || 'Nordlaks'}</p>
          <p><strong>Heading:</strong> {activeMarker.heading || '314'}</p>
          <p><strong>Kurs:</strong> {activeMarker.course || '-'}</p>
          <p><strong>ROT:</strong> {activeMarker.rot || '-'}</p>
          <p><strong>Hastighet:</strong> {activeMarker.speed || '0.2'} knop</p>
          <p><strong>Breddegrad (N):</strong> {activeMarker.latitude}</p>
          <p><strong>Lengdegrad (E):</strong> {activeMarker.longitude}</p>
          <p><strong>Skrog:</strong> {activeMarker.hull_type || 'Enskrogs'}</p>
          <p><strong>Design:</strong> {activeMarker.design || '-'}</p>
          <p><strong>Lengde:</strong> {activeMarker.length || '23 m'}</p>
          <p><strong>Bredde:</strong> {activeMarker.width || '12 m'}</p>
        </div>
      )}
    </div>
  );
};

const getStyles = (theme) => ({
  container: {
    position: 'relative',
    height: 'calc(100vh - 71px)',
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#2e2e2e',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 71px)',
  },
  header: {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#2e2e2e',
  },
  searchInput: {
    flex: 1,
    height: '30px',
    borderRadius: '5px',
    paddingLeft: '10px',
    color: theme === 'light' ? '#000' : '#fff',
    backgroundColor: theme === 'light' ? '#ffffff' : '#555555',
    border: 'none',
  },
  clearButton: {
    marginLeft: '10px',
    padding: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  settingsButton: {
    padding: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  map: {
    position: 'absolute',
    top: '50px', // Plasserer kartet under headeren som har høyde 50px
    left: 0,
    right: 0,
    bottom: 0, // Strekker kartet helt til bunnen av skjermen
    height: '100%', // Tar høyden av hele skjermen minus headerens høyde
  },
  searchResultsList: {
    position: 'absolute',
    top: '60px',
    left: '10px',
    right: '10px',
    backgroundColor: theme === 'light' ? '#fff' : '#333',
    borderRadius: '5px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
  },
  searchResult: {
    padding: '10px',
    borderBottom: `1px solid ${theme === 'light' ? '#ccc' : '#555'}`,
    cursor: 'pointer',
  },
  searchResultName: {
    fontSize: '16px',
    color: theme === 'light' ? '#000' : '#fff',
  },
  searchResultMatch: {
    fontSize: '12px',
    color: theme === 'light' ? '#888' : '#aaa',
  },
  infoPanel: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '350px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#333333',
    color: theme === 'light' ? '#000000' : '#ffffff',
    padding: '25px',
    boxShadow: 'none',
    borderRadius: '0 8px 8px 0',
    transform: 'translateX(0)',
    transition: 'transform 0.3s ease-in-out',
    zIndex: 2000,
    overflowY: 'auto',
  },
  closePanelButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
});


export default MapScreen;
