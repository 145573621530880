import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';
import axios from 'axios';
import { Icon } from '@iconify/react';

const DocumentScreen = () => {
  const hiddenFileInput = useRef(null);
  const { theme } = useTheme();
  const [files, setFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState([{ name: 'Files', folder: null }]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [previewFile, setPreviewFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [pendingUploads, setPendingUploads] = useState([]);
  const [allowUpload, setAllowUpload] = useState(0);

  const styles = getStyles(theme);

  // Fetch files from the API and build file tree
  const fetchFiles = async () => {
    try {
      const response = await axios.get('https://pmh-intern.no:3001/api/files');
      const rawFiles = response.data;

      const buildFileTree = (files) => {
        const fileMap = {};
        files.forEach((file) => {
          file.children = [];
          fileMap[file.id] = file;
        });

        const fileTree = [];
        files.forEach((file) => {
          if (file.parent_id) {
            if (fileMap[file.parent_id]) {
              fileMap[file.parent_id].children.push(file);
            }
          } else {
            fileTree.push(file);
          }
        });

        return fileTree;
      };

      const fileTree = buildFileTree(rawFiles);
      setFiles(fileTree);

      // Beholde den eksisterende mappen (dersom man er inne i en mappe)
      setCurrentPath((prevPath) => {
        if (prevPath.length > 1) {
          // Prøv å finne den valgte mappen igjen basert på ID.
          const lastPath = prevPath[prevPath.length - 1];
          const folderToUpdate = findFolderById(fileTree, lastPath.folder.id);
          if (folderToUpdate) {
            return [...prevPath.slice(0, prevPath.length - 1), { name: folderToUpdate.name, folder: folderToUpdate }];
          }
        }
        return [{ name: 'Files', folder: fileTree }];
      });

    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  // Funksjon for å finne en mappe basert på ID.
  const findFolderById = (files, id) => {
    for (const file of files) {
      if (file.id === id) {
        return file;
      }
      if (file.children) {
        const found = findFolderById(file.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const fetchUserInfo = async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      //setErrorMessage('Du må være logget inn for å se denne informasjonen.');
      return;
    }

    const response = await fetch('https://pmh-intern.no:8502/api/userinfo', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Brukerinfo:', data);

      if (data.can_upload === 1) {
        setAllowUpload(1);
      } else {
        setAllowUpload(0);
      }

      fetchFiles()
    } else {
      const data = await response.json();
      //setErrorMessage(data.message || 'Kunne ikke hente brukerinfo');
    }
  };


  useEffect(() => {
    fetchUserInfo();

  }, []);

  // Handle file upload input change
  const handleFileInputChange = (e) => {
    if (e.target.files.length > 0) {
      const fileToUpload = e.target.files[0];
      setPendingUploads((prev) => [
        ...prev,
        {
          id: Math.random().toString(36).substr(2, 9),
          name: fileToUpload.name,
          file: fileToUpload,
          parent_id: selectedFolder ? selectedFolder.id : null,
        },
      ]);
    }
  };

  // Confirm file upload
  const handleConfirmUpload = async (upload) => {
    const formData = new FormData();
    formData.append('file', upload.file);
    if (upload.parent_id) {
      formData.append('parent_id', upload.parent_id);
    }

    try {
      const response = await axios.post('https://pmh-intern.no:3001/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully:', response.data);
      setPendingUploads((prev) => prev.filter((item) => item.id !== upload.id));
      setTimeout(() => {
        fetchFiles(); // Refresh the file list after upload
      }, 1000); // Refresh the file list after upload
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // Cancel file upload
  const handleCancelUpload = (uploadId) => {
    setPendingUploads((prev) => prev.filter((item) => item.id !== uploadId));
  };

  // Handle creating a new folder
  const handleCreateFolder = async (parentFolder) => {
    const folderName = prompt('Enter the name of the new folder:');
    if (folderName) {
      try {
        const response = await axios.post('https://pmh-intern.no:3001/api/folders', {
          name: folderName,
          parent_id: parentFolder ? parentFolder.id : null,
        });
        console.log('Folder created successfully:', response.data);
        fetchFiles(); // Refresh the file list after creating a new folder
      } catch (error) {
        console.error('Error creating folder:', error);
      }
    }
  };

  // Handle clicking on a folder in the sidebar
  const handleFolderClick = (folder) => {
    setExpandedFolders((prevExpanded) => ({
      ...prevExpanded,
      [folder.id]: true,
    }));
    setSelectedFolder(folder);

    // Oppdater currentPath basert på mappens plassering i hierarkiet
    setCurrentPath((prevPath) => {
      const folderPath = buildPathToFolder(folder, files);
      return [{ name: 'Files', folder: null }, ...folderPath];
    });
  };

  // Handle clicking on a file or folder in the main view
  const handleItemClick = (item) => {
    if (item.type === 'folder') {
      setSelectedFolder(item);

      // Oppdater currentPath basert på mappens plassering i hierarkiet
      setCurrentPath((prevPath) => {
        const folderPath = buildPathToFolder(item, files);
        return [{ name: 'Files', folder: null }, ...folderPath];
      });
    } else {
      setPreviewFile(item);
      fetchFileContent(item);
    }
  };

  // Fetch file content for preview
  const fetchFileContent = async (file) => {
    try {
      const response = await axios.get(`https://pmh-intern.no:3001/api/files/${file.id}`, {
        responseType: 'arraybuffer',
      });

      const fileType = file.name.split('.').pop().toLowerCase();

      if (['txt', 'json', 'csv', 'html', 'xml'].includes(fileType)) {
        // Forhåndsvisning som tekst for vanlige tekstbaserte filer
        const content = new TextDecoder('utf-8').decode(response.data);
        setFileContent(content);
      } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileType)) {
        // Forhåndsvisning som bilde
        const base64String = arrayBufferToBase64(response.data);
        setFileContent(
          <img
            src={`data:image/${fileType};base64,${base64String}`}
            alt={file.name}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        );
      } else if (fileType === 'pdf') {
        // Bruk Blob for PDF-forhåndsvisning
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);

        // Forhåndsvisning som PDF (åpner i en iframe)
        setFileContent(
          <iframe
            src={pdfUrl}
            title={file.name}
            style={{ width: '100%', height: '100%' }}
          />
        );
      } else if (['xls', 'xlsx'].includes(fileType)) {
        // Last ned Excel-filen siden forhåndsvisning ikke kan vises direkte
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        const excelUrl = URL.createObjectURL(blob);

        setFileContent(
          <div>
            <p>Forhåndsvisning er ikke tilgjengelig for Excel-filer.</p>
            <a
              href={excelUrl}
              download={file.name}
              style={styles.downloadLink}
            >
              Last ned Excel-fil
            </a>
          </div>
        );
      } else {
        // Hvis filen ikke kan forhåndsvises, tilbyr vi nedlasting
        const base64String = arrayBufferToBase64(response.data);
        setFileContent(
          <div>
            <p>Forhåndsvisning er ikke tilgjengelig for denne filtypen.</p>
            <a
              href={`data:application/octet-stream;base64,${base64String}`}
              download={file.name}
              style={styles.downloadLink}
            >
              Last ned fil
            </a>
          </div>
        );
      }
    } catch (error) {
      console.error('Error fetching file content:', error);
      // Viser en grå skjerm og melding hvis det oppstår en feil
      setFileContent(
        <div style={{ backgroundColor: 'gray', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
          <p>Det oppstod en feil ved henting av filen. Vennligst prøv igjen senere.</p>
        </div>
      );
    }
  };

  // Helper function to convert ArrayBuffer to Base64
  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  // Handle clicking on the ".." folder to go up one level
  const handleGoUp = () => {
    if (currentPath.length > 1) {
      const newPath = currentPath.slice(0, currentPath.length - 1);
      setCurrentPath(newPath);
      setSelectedFolder(newPath[newPath.length - 1].folder);
      setPreviewFile(null);
      setFileContent(null);
    }
  };

  const buildPathToFolder = (folder, allFiles) => {
    const path = [];
    const findFolderPath = (currentFolder, currentPath) => {
      currentPath.push({ name: currentFolder.name, folder: currentFolder });

      if (currentFolder.id === folder.id) {
        path.push(...currentPath);
        return true;
      }

      if (currentFolder.children) {
        for (const child of currentFolder.children) {
          if (findFolderPath(child, [...currentPath])) {
            return true;
          }
        }
      }

      return false;
    };

    allFiles.forEach((rootFolder) => findFolderPath(rootFolder, []));
    return path;
  };

  // Handle clicking on the breadcrumb to navigate to a specific folder
  const handleBreadcrumbClick = (index) => {
    const newPath = currentPath.slice(0, index + 1);
    setCurrentPath(newPath);
    setSelectedFolder(newPath[index].folder);
    setPreviewFile(null);
    setFileContent(null);
  };

  // Close preview pane
  const handleClosePreview = () => {
    setPreviewFile(null);
    setFileContent(null);
  };

  // Function to determine the appropriate icon based on file type
  const getFileIcon = (file) => {
    if (file.type === 'folder') {
      return (
        <Icon
          icon={'line-md:folder-twotone'}
          style={{ ...styles.fileIcon, color: theme === 'light' ? '#ffa500' : '#ffcc00' }}
        />
      );
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();

    switch (fileExtension) {
      case 'doc':
      case 'docx':
        return (
          <Icon
            icon="line-md:file-document"
            style={{ ...styles.fileIcon, color: '#1E90FF' }} // Blå for Word-dokumenter
          />
        );
      case 'xls':
      case 'xlsx':
        return (
          <Icon
            icon="line-md:file-document"
            style={{ ...styles.fileIcon, color: '#228B22' }} // Grønn for Excel-filer
          />
        );
      case 'txt':
        return (
          <Icon
            icon="line-md:file-document"
            style={{ ...styles.fileIcon, color: '#696969' }} // Grå for tekstfiler
          />
        );
      case 'pdf':
        return (
          <Icon
            icon="line-md:file-document"
            style={{ ...styles.fileIcon, color: '#FF0000' }} // Rød for PDF-filer
          />
        );
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'webp':
        return (
          <Icon
            icon="line-md:image-twotone"
            style={{ ...styles.fileIcon, color: '#FFA07A' }} // Lys oransje for bilder
          />
        );
      default:
        return (
          <Icon
            icon="line-md:file"
            style={{ ...styles.fileIcon, color: '#808080' }} // Standard grå for ukjente filer
          />
        );
    }
  };

  return (
    <div style={styles.container}>
      {/* Sidebar showing folders */}
      <div style={styles.sidebar}>
        <h3 style={styles.sidebarHeader}>Files</h3>
        {files.map((folder) => (
          folder.type === 'folder' && (
            <div key={folder.id}>
              <div
                style={{
                  ...styles.sidebarItem,
                  backgroundColor: selectedFolder && selectedFolder.id === folder.id ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
                }}
                onClick={() => handleFolderClick(folder)}
              >
                <Icon icon={'mdi:folder-open'} style={styles.folderIcon} />
                {folder.name}
              </div>
              {expandedFolders[folder.id] && folder.children.filter(child => child.type === 'folder').length > 0 && (
                <div style={styles.subFolderContainer}>
                  {folder.children.filter(child => child.type === 'folder').map((child) => (
                    <div
                      key={child.id}
                      style={{
                        ...styles.sidebarSubItem,
                        backgroundColor: selectedFolder && selectedFolder.id === child.id ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
                      }}
                      onClick={() => handleFolderClick(child)}
                    >
                      <Icon icon={'mdi:folder-open'} style={styles.folderIcon} />
                      {child.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )
        ))}
      </div>

      {/* Main view showing content of the current folder and preview pane */}
      <div style={previewFile ? styles.mainContentContainerWithPreview : styles.mainContentContainer}>
        <div style={styles.mainContent}>
          <div style={styles.breadcrumbContainer}>
            {currentPath.map((crumb, index) => (
              <React.Fragment key={index}>
                <span style={styles.breadcrumb} onClick={() => handleBreadcrumbClick(index)}>
                  {crumb.name}
                </span>
                {index < currentPath.length - 1 && <span style={styles.breadcrumbSeparator}> / </span>}
              </React.Fragment>
            ))}
          </div>
          <div style={styles.filesListContainer}>
            {currentPath.length > 1 && (
              <div
                style={styles.fileListItem}
                onClick={handleGoUp}
              >
                <Icon icon="line-md:folder-arrow-up-twotone" style={{ ...styles.fileIcon, color: theme === 'light' ? '#ffa500' : '#ffcc00' }} />
                ..
              </div>
            )}
            {selectedFolder && selectedFolder.children ? (
              [
                ...selectedFolder.children.filter(item => item.type === 'folder'),
                ...selectedFolder.children.filter(item => item.type !== 'folder')
              ].map((item) => (
                <div
                  key={item.id}
                  style={styles.fileListItem}
                  onClick={() => handleItemClick(item)}
                >
                  {getFileIcon(item)}
                  {item.name}
                </div>
              ))
            ) : (
              files.map((item) => (
                <div
                  key={item.id}
                  style={styles.fileListItem}
                  onClick={() => handleItemClick(item)}
                >
                  {getFileIcon(item)}
                  {item.name}
                </div>
              ))
            )}
            {allowUpload == 1 && (
              <>
                <div
                  key={`upload-${selectedFolder ? selectedFolder.id : 'root'}`}
                  style={{ ...styles.fileListItem, cursor: 'pointer' }}
                  onClick={() => hiddenFileInput.current.click()}
                >
                  <Icon icon="line-md:file-upload" style={{ ...styles.fileIcon, color: theme === 'light' ? '#007bff' : '#007bff' }} />
                  Last opp fil
                </div>
                <div
                  key={`create-folder-${selectedFolder ? selectedFolder.id : 'root'}`}
                  style={{ ...styles.fileListItem, cursor: 'pointer' }}
                  onClick={() => handleCreateFolder(selectedFolder)}
                >
                  <Icon icon="line-md:folder-plus" style={{ ...styles.fileIcon, color: theme === 'light' ? '#28a745' : '#218838' }} />
                  Opprett ny mappe
                </div>
                <input
                  key={`file-input-${selectedFolder ? selectedFolder.id : 'root'}`}
                  type="file"
                  ref={hiddenFileInput}
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                />
              </>
            )}
            {pendingUploads.map((upload) => (
              <div key={upload.id} style={{ ...styles.fileListItem, backgroundColor: theme === 'light' ? '#f0f8ff' : '#3e3e3e' }}>
                <Icon icon="line-md:file-upload" style={{ ...styles.fileIcon, color: theme === 'light' ? '#007bff' : '#007bff' }} />
                {upload.name}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
                  <Icon
                    icon="mdi:check-circle-outline"
                    style={{ color: '#28a745', cursor: 'pointer' }}
                    onClick={() => handleConfirmUpload(upload)}
                  />
                  <Icon
                    icon="mdi:close-circle-outline"
                    style={{ color: '#dc3545', cursor: 'pointer' }}
                    onClick={() => handleCancelUpload(upload.id)}
                  />
                </div>
              </div>
            ))}


          </div>
        </div>

        {/* Preview pane */}
        {previewFile && (
          <div style={styles.previewPane}>
            <div style={styles.previewHeader}>
              <h3>Forhåndsvisning av: {previewFile.name}</h3>
              <button style={styles.closeButton} onClick={handleClosePreview}><Icon icon="mdi:close" style={{ fontSize: '32px' }} />
              </button>
            </div>
            <div style={styles.previewContent}>{fileContent}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const getStyles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 71px)',
    backgroundColor: theme === 'light' ? '#ffffff' : '#1e1e1e',
  },
  sidebar: {
    width: '250px',
    backgroundColor: theme === 'light' ? '#f4f4f4' : '#333333',
    padding: '15px',
    borderRight: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
  },
  sidebarHeader: {
    color: theme === 'light' ? '#000000' : '#ffffff',
    marginBottom: '15px',
  },
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  sidebarSubItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 20px',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  folderIcon: {
    marginRight: '10px',
  },
  subFolderContainer: {
    paddingLeft: '15px',
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  mainContentContainerWithPreview: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '50%',
  },
  mainContent: {
    flex: 1,
    padding: '20px',
  },
  breadcrumbContainer: {
    marginBottom: '20px',
    color: theme === 'light' ? '#000000' : '#ffffff',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumb: {
    display: 'inline',
    cursor: 'pointer',
  },
  breadcrumbSeparator: {
    margin: '0 5px',
  },
  filesListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  fileListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#2e2e2e',
    boxShadow: theme === 'light' ? '0 1px 4px rgba(0,0,0,0.1)' : '0 1px 4px rgba(0,0,0,0.3)',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  fileIcon: {
    fontSize: '20px',
    marginRight: '10px',
  },
  previewPane: {
    width: '50%',
    padding: '20px',
    borderLeft: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
    backgroundColor: theme === 'light' ? '#f9f9f9' : '#2e2e2e',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  previewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  previewContent: {
    overflow: 'none',
    height: '100%',
    maxHeight: 'calc(100% - 70px)',
  },
  addOptionsContainer: {
    marginTop: '20px',
    display: 'flex',
    gap: '10px',
  },
});

export default DocumentScreen;
