import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

const RegisterScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { theme } = useTheme();
  const styles = getStyles(theme);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Passordene stemmer ikke overens. Vennligst prøv igjen.');
      return;
    }

    // Send registreringsforespørsel til backend
    const response = await fetch('https://pmh-intern.no:8502/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, email }),
    });

    const data = await response.json();
    if (response.status === 201) {
      alert('Registrering vellykket! Kontoen din venter på godkjenning.');
      navigate('/login');
    } else {
      alert(data.message || 'Registrering mislyktes, vennligst prøv igjen.');
    }
  };

  return (
    <div style={styles.registerContainer}>
      <div style={styles.registerCard}>
        <h2 style={styles.headerText}>Registrer deg</h2>
        <p style={styles.subHeaderText}>Velkommen til PMH's internside, vennligst registrer deg</p>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="Brukernavn"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.registerInput}
          />
          <input
            type="email"
            placeholder="E-post"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.registerInput}
          />
          <input
            type="password"
            placeholder="Passord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.registerInput}
          />
          <input
            type="password"
            placeholder="Bekreft passord"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={styles.registerInput}
          />
          <button type="submit" style={styles.registerButton}>Registrer deg</button>
        </form>
      </div>
    </div>
  );
};

const getStyles = (theme) => {
  const isLight = theme === 'light';

  return {
    registerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      background: isLight ? '#f0f2f5' : '#1a1a1a',
      transition: 'background 0.3s ease',
    },
    registerCard: {
      backgroundColor: isLight ? '#ffffff' : '#2c2c2c',
      padding: '40px',
      borderRadius: '15px',
      boxShadow: isLight ? '0 10px 30px rgba(0, 0, 0, 0.1)' : '0 10px 30px rgba(0, 0, 0, 0.5)',
      textAlign: 'center',
      maxWidth: '400px',
      width: '100%',
      boxSizing: 'border-box',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    },
    headerText: {
      color: isLight ? '#333' : '#d3d754',
      marginBottom: '10px',
      fontSize: '2rem',
      fontWeight: 'bold',
      transition: 'color 0.3s ease',
    },
    subHeaderText: {
      color: isLight ? '#666' : '#aaa',
      marginBottom: '30px',
      fontSize: '1rem',
      transition: 'color 0.3s ease',
    },
    registerInput: {
      width: 'calc(100% - 20px)',
      padding: '15px',
      marginBottom: '20px',
      backgroundColor: isLight ? '#f9f9f9' : '#3d3d3d',
      border: `2px solid ${isLight ? '#ddd' : '#555'}`,
      borderRadius: '8px',
      fontSize: '1rem',
      color: isLight ? '#333' : '#f9f9f9',
      transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
      boxSizing: 'border-box',
    },
    registerButton: {
      width: 'calc(100% - 20px)',
      padding: '15px',
      backgroundColor: isLight ? '#15606e' : '#4c9e9e',
      color: '#ffffff',
      border: 'none',
      borderRadius: '8px',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
      boxSizing: 'border-box',
    },
    registerButtonHover: {
      backgroundColor: isLight ? '#0e4a52' : '#3b8585',
    },
  };
};

export default RegisterScreen;
