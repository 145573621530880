import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import { ThemeProvider, useTheme } from './context/ThemeContext.js';
import ProtectedRoute from './components/ProtectedRoute';
import { Icon } from '@iconify/react';


// Importer skjermene
import LoginScreen from './screens/LoginScreen.js';
import RegisterScreen from './screens/RegisterScreen.js';

import ToolsScreen from './screens/ToolsScreen.js';
import MapScreen from './screens/MapScreen.js';
import ListScreen from './screens/ListScreen.js';
import SettingsScreen from './screens/SettingsScreen.js';
import HomeScreen from './screens/HomeScreen.js';
import DocumentScreen from './screens/DocumentScreen.js';
import ShipListScreen from './screens/shipListScreen.js';

// Dropdown-menu-komponent
const DropdownMenu = ({ options, onClose }) => (
  <div className="dropdown-container">
    {options.map((option, index) => (
      <div key={index} onClick={onClose} className="dropdown-item">
        {option}
      </div>
    ))}
  </div>
);

function MainTabNavigator() {
  const { theme, toggleTheme } = useTheme();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <div className="tab-navigator" style={{ backgroundColor: theme === 'light' ? '#fff' : '#333' }}>
      <nav className="tab-bar">
        <NavLink to="/" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Hjem</NavLink>
        <NavLink to="/documents" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Dokumenter</NavLink>
        <NavLink to="/map" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Kart</NavLink>
        <NavLink to="/list" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Lister</NavLink>
        <NavLink to="/tools" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Verktøy</NavLink>
        <NavLink to="/settings" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Innstillinger</NavLink>
        <NavLink to="/ship-list" className={({ isActive }) => isActive ? 'active-tab' : 'tab'}>Skipsliste</NavLink>
      </nav>


  <button onClick={toggleTheme} className="theme-toggle-button">
    {theme === 'light' ? (
      <Icon icon="line-md:sunny-filled-loop" style={{ color: '#ffdd00', fontSize: '36px' }} />
    ) : (
      <Icon icon="line-md:moon-loop" style={{color: '#c7b223', fontSize: '36px' }} />
    )}
  </button>

    </div>
  );
}

function ThemedAppStyles() {
  const { theme } = useTheme();

  // CSS-stiler for hele applikasjonen
const styles = `
  .tab-navigator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background-color: ${theme === 'light' ? '#ffffff' : '#282c34'};
    box-shadow: ${theme === 'light' ? '0 4px 12px rgba(0, 0, 0, 0.1)' : '0 4px 12px rgba(255, 255, 255, 0.1)'};
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 100;
    border-bottom: ${theme === 'light' ? '1px solid #ddd' : '1px solid #444'};
  }

  .tab-bar {
    display: flex;
    gap: 20px;
  }

  .tab {
    padding: 12px 18px;
    text-decoration: none;
    color: ${theme === 'light' ? '#000000' : '#ffffff'};
    font-weight: 700;
    transition: color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    border-radius: 6px;
  }

  .tab:hover {
    background-color: ${theme === 'light' ? '#f0f0f0' : '#444c56'};
    color: ${theme === 'light' ? '#1e65d6' : '#61dafb'};
    box-shadow: ${theme === 'light' ? '0px 4px 8px rgba(0, 0, 0, 0.15)' : '0px 4px 8px rgba(255, 255, 255, 0.15)'};
  }

  .active-tab {
    background-color: ${theme === 'light' ? '#e0e0e0' : '#3a3f45'};
    color: ${theme === 'light' ? '#007bff' : '#11dafb'};
    box-shadow: none;
    text-decoration: none;
    font-weight: 700;
    padding: 12px 18px;
    border-radius: 6px;
  }

  .theme-toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: ${theme === 'light' ? '#000000' : '#ffffff'};
    margin-left: auto;
    transition: color 0.3s ease;
  }

  .theme-toggle-button:hover {
    color: #61dafb;
  }

  @media (max-width: 768px) {
    .tab-navigator {
      padding: 0 15px;
      height: 60px;
    }

    .tab {
      padding: 8px 12px;
      font-size: 14px;
    }
  }
`;

  // Legg til stilene i dokumentet
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  return null;
}

// Hovedappen med ThemeProvider og Router
export default function App() {
  return (
    <ThemeProvider>
      <Router>
        <ThemedAppStyles />
        <Routes>
          <Route path="/login" element={<LoginScreen />} /> {/* Legg til en login-side */}
          <Route path="/register" element={<RegisterScreen />} /> {/* Legg til en register-side */}
          <Route path="*" element={<MainContent />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}


async function fetchUserInfo(token) {
  const response = await fetch('https://pmh-intern.no:8502/api/userinfo', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  

  return await response.json();

}


function MainContent() {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsAuthenticated(false);
      return;
    }

    fetchUserInfo(token)
      .then(data => {
        console.log('Brukerinfo:', data);
        setIsAuthenticated(true);
      })
      .catch(error => {
        console.error(error.message);
        setErrorMessage(error.message);
        setIsAuthenticated(false);
      });
  }, []); // Kjør én gang ved lasting av komponenten

  return (
    <>
      {location.pathname !== '/login' && location.pathname !== '/register' && isAuthenticated && <MainTabNavigator />} {/* Skjul nav-baren på login- og register-siden */}
      <Routes>
        <Route path="/" element={
          <ProtectedRoute>
            <HomeScreen />
          </ProtectedRoute>
        } />
        <Route path="/documents" element={
          <ProtectedRoute>
            <DocumentScreen />
          </ProtectedRoute>
        } />
        <Route path="/map" element={
          <ProtectedRoute>
            <MapScreen />
          </ProtectedRoute>
        } />
        <Route path="/list" element={
          <ProtectedRoute>
            <ListScreen />
          </ProtectedRoute>
        } />
        <Route path="/tools" element={
          <ProtectedRoute>
            <ToolsScreen />
          </ProtectedRoute>
        } />
        <Route path="/settings" element={
          <ProtectedRoute>
            <SettingsScreen />
          </ProtectedRoute>
        } />
        <Route path="/ship-list" element={
          <ProtectedRoute>
            <ShipListScreen />
          </ProtectedRoute>
        } />
      </Routes>
    </>
  );
}
