import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { Icon } from '@iconify/react';
import axios from 'axios';

const SettingsScreen = () => {
  const { theme } = useTheme();
  const [selectedMenu, setSelectedMenu] = useState('users'); // Default: "users"
  const [users, setUsers] = useState([]);
  const styles = getStyles(theme);

  useEffect(() => {
    // Fetch all users from the API
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
    
        if (!token) {
          //setErrorMessage('Du må være logget inn for å se denne informasjonen.');
          return;
        }

        const response = await fetch('https://pmh-intern.no:8502/api/users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Feil ved henting av brukere');
        }

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Handlere for menyvalg
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  // Render innholdet basert på valgt meny
  const renderContent = () => {
    switch (selectedMenu) {
      case 'tool_1':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 1</h2>
            <p>Her kan du bruke verktøy 1.</p>
          </div>
        );
      case 'tool_2':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 2</h2>
            <p>Her kan du bruke verktøy 2.</p>
          </div>
        );
      case 'tool_3':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 3</h2>
            <p>Her kan du bruke verktøy 3.</p>
          </div>
        );
      case 'tool_4':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 4</h2>
            <p>Her kan du bruke verktøy 4.</p>
          </div>
        );
      case 'tool_5':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 5</h2>
            <p>Her kan du bruke verktøy 5.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={styles.container}>
      {/* Sidebar med innstillingsmenyer */}
      <div style={styles.sidebar}>
        <h3 style={styles.sidebarHeader}>Verktøy</h3>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'tool_1' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('tool_1')}
        >
          <Icon icon="entypo:tools" style={styles.menuIcon} />
          Verktøy 1
        </div>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'tool_2' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('tool_2')}
        >
          <Icon icon="entypo:tools" style={styles.menuIcon} />
          Verktøy 2
        </div>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'tool_3' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('tool_3')}
        >
          <Icon icon="entypo:tools" style={styles.menuIcon} />
          Verktøy 3
        </div>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'tool_4' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('tool_4')}
        >
          <Icon icon="entypo:tools" style={styles.menuIcon} />
          Verktøy 4
        </div>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'tool_5' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('tool_5')}
        >
          <Icon icon="entypo:tools" style={styles.menuIcon} />
          Verktøy 5
        </div>
      </div>

      {/* Hovedinnhold som viser innstillingene */}
      <div style={styles.mainContent}>{renderContent()}</div>
    </div>
  );
};

// Funksjon for å hente CSS-stiler basert på tema
const getStyles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 71px)',
    backgroundColor: theme === 'light' ? '#ffffff' : '#1e1e1e',
  },
  sidebar: {
    width: '250px',
    backgroundColor: theme === 'light' ? '#f4f4f4' : '#333333',
    padding: '15px',
    borderRight: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
  },
  sidebarHeader: {
    color: theme === 'light' ? '#000000' : '#ffffff',
    marginBottom: '15px',
  },
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
  },
  menuIcon: {
    marginRight: '10px',
  },
  mainContent: {
    flex: 1,
    padding: '20px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#2e2e2e',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  contentContainer: {
    backgroundColor: theme === 'light' ? '#f9f9f9' : '#3a3a3a',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: theme === 'light' ? '0 1px 4px rgba(0,0,0,0.1)' : '0 1px 4px rgba(0,0,0,0.3)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#3e3e3e',
    boxShadow: theme === 'light' ? '0 2px 8px rgba(0,0,0,0.1)' : '0 2px 8px rgba(0,0,0,0.5)',
  },
  th: {
    borderBottom: `2px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
    padding: '15px',
    textAlign: 'left',
    color: theme === 'light' ? '#000000' : '#ffffff',
    backgroundColor: theme === 'light' ? '#f0f0f0' : '#555555',
  },
  td: {
    borderBottom: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
    padding: '15px',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  tdEmpty: {
    textAlign: 'center',
    padding: '20px',
    color: theme === 'light' ? '#888888' : '#bbbbbb',
  },
  tr: {
    transition: 'background-color 0.3s ease',
  },
  trHover: {
    backgroundColor: theme === 'light' ? '#f9f9f9' : '#444444',
  },
});

export default SettingsScreen;
