import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { apiSFTPAppFetch } from '../functions/apiSFTPAppService';

const PMHShipsTableScreen = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [pmhShips, setPmhShips] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredShips, setFilteredShips] = useState([]);
  const [showAllShips, setShowAllShips] = useState(false);
  const [loading, setLoading] = useState(false);
  const styles = getStyles(theme);

  useEffect(() => {
    const fetchShips = async () => {
      setLoading(true);
      try {
        const endpoint = showAllShips ? '/all_ship_info_table' : '/pmh_ship_info_table';
        const data = await apiSFTPAppFetch(endpoint);
        if (data) {
          setPmhShips(Object.values(data));
          setFilteredShips(Object.values(data));
        }
      } catch (error) {
        console.error('Feil ved henting av skip:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchShips();
  }, [showAllShips]);

  useEffect(() => {
    const searchWords = searchQuery.toLowerCase().split(' ').filter(Boolean);
    const filteredData = pmhShips.filter((ship) => {
      const shipString = [
        ship.name,
        ship.yard,
        ship.build_number,
        ship.ship_owner,
        ship.prev_names,
        ship.design,
        ship.imo,
        ship.call_sign,
        ship.keywords,
        ship.po,
        ship.sys_DWC,
      ]
        .join(' ')
        .toLowerCase();
      return searchWords.every((word) => shipString.includes(word));
    });
    setFilteredShips(filteredData);
  }, [searchQuery, pmhShips]);

  const navigateToShipDetail = (mmsi) => {
    navigate(`/ship/${mmsi}`);
  };

  return (
    <div style={styles.container}>
      <div style={styles.searchContainer}>
        <input
          style={styles.input}
          type="text"
          placeholder="Søk etter skip, verft, byggenummer..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div style={styles.checkboxContainer}>
          <label style={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={showAllShips}
              onChange={(e) => setShowAllShips(e.target.checked)}
            />
            Alle skip
          </label>
        </div>
      </div>
      {loading ? (
        <div style={styles.loading}>Laster...</div>
      ) : (
        <div style={styles.shipsList}>
          {filteredShips.length > 0 ? (
            filteredShips.map((ship) => (
              <div
                key={ship.mmsi}
                style={styles.shipItem}
                onClick={() => navigateToShipDetail(ship.mmsi)}
              >
                {ship.img_url ? (
                  <img src={ship.img_url} alt={ship.name} style={styles.shipImage} />
                ) : (
                  <div style={styles.placeholderImage} />
                )}
                <div style={styles.shipDetails}>
                  <h3 style={styles.shipName}>{ship.name || 'Ukjent'}</h3>
                  <p style={styles.shipInfo}>Verft: {ship.yard || 'Ukjent'}</p>
                  <p style={styles.shipInfo}>Byggenummer: {ship.build_number || 'Ukjent'}</p>
                  <p style={styles.shipInfo}>Rederi: {ship.ship_owner || 'Ukjent'}</p>
                </div>
              </div>
            ))
          ) : (
            <div style={styles.noShipsFound}>Ingen skip funnet</div>
          )}
        </div>
      )}
    </div>
  );
};

const getStyles = (theme) => ({
  container: {
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#2e2e2e',
    color: theme === 'light' ? '#000000' : '#ffffff',
    height: 'calc(100vh - 111px)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  input: {
    width: '300px', // Reduser bredden til søkefeltet
    padding: '10px',
    border: theme === 'light' ? '1px solid #cccccc' : '1px solid #555555',
    borderRadius: '5px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#555555',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  checkboxContainer: {
    marginLeft: '10px',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  loading: {
    textAlign: 'center',
    fontSize: '18px',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  shipsList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '800px',
  },
  shipItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    borderBottom: theme === 'light' ? '1px solid #cccccc' : '1px solid #555555',
    cursor: 'pointer',
    backgroundColor: theme === 'light' ? '#f9f9f9' : '#444444',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  shipImage: {
    width: '60px',
    height: '60px',
    borderRadius: '10px',
    marginRight: '15px',
  },
  placeholderImage: {
    width: '60px',
    height: '60px',
    backgroundColor: '#cccccc',
    borderRadius: '10px',
    marginRight: '15px',
  },
  shipDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  shipName: {
    fontWeight: 'bold',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  shipInfo: {
    fontSize: '12px',
    color: theme === 'light' ? '#666666' : '#aaaaaa',
  },
  noShipsFound: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
    color: theme === 'light' ? '#999999' : '#aaaaaa',
  },
});


export default PMHShipsTableScreen;
