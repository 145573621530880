import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { Icon } from '@iconify/react';
import axios from 'axios';

const SettingsScreen = () => {
  const { theme } = useTheme();
  const [selectedMenu, setSelectedMenu] = useState('users'); // Default: "users"
  const [users, setUsers] = useState([]);
  const styles = getStyles(theme);

  useEffect(() => {
    // Fetch all users from the API
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
    
        if (!token) {
          return;
        }

        const response = await fetch('https://pmh-intern.no:8502/api/users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Feil ved henting av brukere');
        }

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Handlere for menyvalg
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  // Funksjon for å godkjenne bruker
  const handleApproveUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.patch(
        `https://pmh-intern.no:8502/api/users/${userId}/approve`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, approved: true } : user
          )
        );
      }
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  // Funksjon for å slette bruker
  const handleDeleteUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.patch(
        `https://pmh-intern.no:8502/api/users/${userId}/delete`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, status: 'slettet' } : user
          )
        );
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  // Funksjon for å logge ut bruker
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload(); // Refresh the page to reset state
  };

  // Render innholdet basert på valgt meny
  const renderContent = () => {
    switch (selectedMenu) {
      case 'users':
        return (
          <div style={styles.contentContainer}>
            <h2>Brukerinnstillinger</h2>
            <p>Her kan du administrere brukere, legge til nye eller fjerne eksisterende brukere.</p>
            <h3>Godkjente brukere</h3>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>ID</th>
                  <th style={styles.th}>Brukernavn</th>
                  <th style={styles.th}>E-post</th>
                  <th style={styles.th}>Godkjent</th>
                  <th style={styles.th}>Rolle</th>
                  <th style={styles.th}>Kan laste opp</th>
                  <th style={styles.th}>Siste innlogging</th>
                  <th style={styles.th}>Innloggingsforsøk</th>
                  <th style={styles.th}>Konto sperret frem til</th>
                  <th style={styles.th}>Status</th>
                  <th style={styles.th}>Opprettet</th>
                </tr>
              </thead>
              <tbody>
                {users.filter(user => user.approved).length > 0 ? (
                  users.filter(user => user.approved).map((user) => (
                    <tr key={user.id} style={styles.tr}>
                      <td style={styles.td}>{user.id}</td>
                      <td style={styles.td}>{user.username}</td>
                      <td style={styles.td}>{user.email}</td>
                      <td style={styles.td}>{user.approved ? 'Ja' : 'Nei'}</td>
                      <td style={styles.td}>{user.role}</td>
                      <td style={styles.td}>{user.can_upload ? 'Ja' : 'Nei'}</td>
                      <td style={styles.td}>{user.last_login}</td>
                      <td style={styles.td}>{user.failed_login_attempts}</td>
                      <td style={styles.td}>{user.account_locked_until}</td>
                      <td style={styles.td}>{user.status}</td>
                      <td style={styles.td}>{user.created_at}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="11" style={styles.tdEmpty}>Ingen godkjente brukere funnet.</td>
                  </tr>
                )}
              </tbody>
            </table>
            
            <h3>Nye brukere</h3>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>ID</th>
                  <th style={styles.th}>Brukernavn</th>
                  <th style={styles.th}>E-post</th>
                  <th style={styles.th}>IP-adresse</th>
                  <th style={styles.th}>Opprettet</th>
                  <th style={styles.th}>Handlinger</th>
                </tr>
              </thead>
              <tbody>
                {users.filter(user => !user.approved && user.status !== 'slettet').length > 0 ? (
                  users.filter(user => !user.approved && user.status !== 'slettet').map((user) => (
                    <tr key={user.id} style={styles.tr}>
                      <td style={styles.td}>{user.id}</td>
                      <td style={styles.td}>{user.username}</td>
                      <td style={styles.td}>{user.email}</td>
                      <td style={styles.td}>{user.ip_address}</td>
                      <td style={styles.td}>{user.created_at}</td>
                      <td style={styles.td}>
                        <button onClick={() => handleApproveUser(user.id)} style={styles.button}>
                          <Icon icon="mdi:check" /> Godkjenn
                        </button>
                        <button onClick={() => handleDeleteUser(user.id)} style={styles.buttonDelete}>
                          <Icon icon="mdi:close" /> Slett
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={styles.tdEmpty}>Ingen nye brukere funnet.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      case 'profile':
        return (
          <div style={styles.contentContainer}>
            <h2>Profilinnstillinger</h2>
            <p>Her kan du oppdatere din personlige profilinformasjon, som navn, e-post og passord.</p>
            <button onClick={handleLogout} style={styles.buttonLogout}>
              <Icon icon="mdi:logout" /> Logg ut
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={styles.container}>
      {/* Sidebar med innstillingsmenyer */}
      <div style={styles.sidebar}>
        <h3 style={styles.sidebarHeader}>Innstillinger</h3>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'users' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('users')}
        >
          <Icon icon="mdi:account-group" style={styles.menuIcon} />
          Brukere
        </div>
        <div
          style={{
            ...styles.sidebarItem,
            backgroundColor: selectedMenu === 'profile' ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
          }}
          onClick={() => handleMenuClick('profile')}
        >
          <Icon icon="mdi:account-circle" style={styles.menuIcon} />
          Profil
        </div>
      </div>

      {/* Hovedinnhold som viser innstillingene */}
      <div style={styles.mainContent}>{renderContent()}</div>
    </div>
  );
};

// Funksjon for å hente CSS-stiler basert på tema
const getStyles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 71px)',
    backgroundColor: theme === 'light' ? '#ffffff' : '#1e1e1e',
  },
  sidebar: {
    width: '250px',
    backgroundColor: theme === 'light' ? '#f4f4f4' : '#333333',
    padding: '15px',
    borderRight: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
  },
  sidebarHeader: {
    color: theme === 'light' ? '#000000' : '#ffffff',
    marginBottom: '15px',
  },
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
  },
  menuIcon: {
    marginRight: '10px',
  },
  mainContent: {
    flex: 1,
    padding: '20px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#2e2e2e',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  contentContainer: {
    backgroundColor: theme === 'light' ? '#f9f9f9' : '#3a3a3a',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: theme === 'light' ? '0 1px 4px rgba(0,0,0,0.1)' : '0 1px 4px rgba(0,0,0,0.3)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#3e3e3e',
    boxShadow: theme === 'light' ? '0 2px 8px rgba(0,0,0,0.1)' : '0 2px 8px rgba(0,0,0,0.5)',
  },
  th: {
    borderBottom: `2px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
    padding: '15px',
    textAlign: 'left',
    color: theme === 'light' ? '#000000' : '#ffffff',
    backgroundColor: theme === 'light' ? '#f0f0f0' : '#555555',
  },
  td: {
    borderBottom: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
    padding: '15px',
    color: theme === 'light' ? '#000000' : '#ffffff',
  },
  tdEmpty: {
    textAlign: 'center',
    padding: '20px',
    color: theme === 'light' ? '#888888' : '#bbbbbb',
  },
  tr: {
    transition: 'background-color 0.3s ease',
  },
  trHover: {
    backgroundColor: theme === 'light' ? '#f9f9f9' : '#444444',
  },
  button: {
    padding: '8px 12px',
    margin: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#4caf50',
    color: '#ffffff',
    border: 'none',
  },
  buttonDelete: {
    padding: '8px 12px',
    margin: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#f44336',
    color: '#ffffff',
    border: 'none',
  },
  buttonLogout: {
    padding: '10px 15px',
    marginTop: '20px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#ff5722',
    color: '#ffffff',
    border: 'none',
  },
});

export default SettingsScreen;
