import React from 'react';
import { useTheme } from '../context/ThemeContext';

const HomeScreen = () => {
  const { theme } = useTheme();
  const styles = getStyles(theme);

  // Eksempler på nyheter
  const newsItems = [
    {
      message: 'Vi ønsker å informere om at det blir en viktig oppdatering av systemet på fredag kl. 22:00.',
      author: 'Kari Nordmann',
      dateTime: '15. november 2024, 14:30',
    },
    {
      message: 'Nye retningslinjer for hjemmekontor er nå tilgjengelige i interne dokumenter.',
      author: 'Ola Hansen',
      dateTime: '14. november 2024, 09:15',
    },
    {
      message: 'Husk at vi har allmøte mandag morgen kl. 09:00. Vennligst møt opp i møterom 2.',
      author: 'Per Olsen',
      dateTime: '13. november 2024, 16:45',
    },
  ];

  return (
    <div style={styles.screenContainer}>
      <header style={styles.header}>
        <h1 style={styles.title}>Velkommen til PMHs internside</h1>
      </header>
      <main style={styles.mainContent}>
        <section style={styles.quickLinks}>
          <h2 style={styles.sectionTitle}>Hurtiglenker</h2>
          <div style={styles.linksContainer}>
            <button style={styles.linkButton}>Kundeoversikt</button>
            <button style={styles.linkButton}>Interne Dokumenter</button>
            <button style={styles.linkButton}>Nyheter</button>
            <button style={styles.linkButton}>Ansattportal</button>
          </div>
        </section>
        <section style={styles.news}>
          <h2 style={styles.sectionTitle}>Nyheter og Kunngjøringer</h2>
          {newsItems.map((news, index) => (
            <div key={index} style={styles.newsCard}>
              <p style={styles.newsText}>{news.message}</p>
              <div style={styles.newsFooter}>
                <span style={styles.newsAuthor}>{news.author}</span>
                <span style={styles.newsDateTime}>{news.dateTime}</span>
              </div>
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};

const getStyles = (theme) => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    height: '100vh',
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#333',
    color: theme === 'light' ? '#000' : '#fff',
  },
  header: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  mainContent: {
    width: '100%',
    maxWidth: '800px',
  },
  quickLinks: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  linksContainer: {
    display: 'flex',
    gap: '10px',
  },
  linkButton: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: theme === 'light' ? '#007bff' : '#555',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  linkButtonHover: {
    backgroundColor: theme === 'light' ? '#0056b3' : '#444',
  },
  news: {
    marginBottom: '20px',
  },
  newsCard: {
    backgroundColor: theme === 'light' ? '#fff' : '#2a2a2a',
    padding: '15px',
    borderRadius: '10px',
    boxShadow: theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.3)',
    marginBottom: '15px',
    transition: 'transform 0.2s',
  },
  newsCardHover: {
    transform: 'scale(1.02)',
  },
  newsText: {
    fontSize: '16px',
    marginBottom: '10px',
    lineHeight: '1.5',
  },
  newsFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: theme === 'light' ? '#666' : '#bbb',
  },
  newsAuthor: {
    fontWeight: 'bold',
  },
  newsDateTime: {
    fontStyle: 'italic',
  },
});

export default HomeScreen;
