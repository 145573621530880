import React from 'react';
import { useTheme } from '../context/ThemeContext';

const ListScreen = () => {
  const { theme } = useTheme();
  const styles = getStyles(theme);

  return (
    <div style={styles.screenContainer}>
      <h1 style={styles.screenText}>Lister</h1>
    </div>
  );
};

const getStyles = (theme) => ({
  screenContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 71px)',
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#333',
  },
  screenText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme === 'light' ? '#000' : '#fff',
  },
});

export default ListScreen;